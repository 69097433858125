// src/Config.js
const Config = {
  serverUrl: process.env.REACT_APP_SERVER_URL,
  basename: '/',
  serverGetTimeout: 30000,
  serverGetDiagramTimeout: 30000,
  TypingDelay: 300,
};

export default Config;
